import React , { useState, useEffect }from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Container } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Web3 from 'web3';

let selectedAccount, signatureReceipt;
let alreadySignIn = false
window.userAddress = null;

function truncateAddress(address) {
    if (!address) {
        return "";
    }
    return `${address.substr(0, 5)}...${address.substr(
          address.length - 5,
          address.length
        )}`;
}

// Display or remove the users know address on the frontend
function showAddress() {
    if (!localStorage.userAddress) {
        //document.getElementById("userAddress").innerText = "";
        //document.getElementById("logoutButton").classList.add("hidden");
        console.log("Show current user address: ", localStorage.userAddress)
        return false;
    }

    //document.getElementById(
    //    "userAddress"
    //).innerText = `ETH Address: ${truncateAddress(window.userAddress)}`;
    //document.getElementById("logoutButton").classList.remove("hidden");
    console.log("Show current user address: ", `ETH Address: ${truncateAddress(localStorage.userAddress)}`)
}

async function login() {
    if(!window.ethereum){ 
        alert("Please Install MetaMask!"); 
        return;
    } 

    //if(alreadySignIn == false){
    //    alert("Please Connect to MetaMask!"); 
    //    return;
    //}

    if(window.ethereum){
        window.web3 = new Web3(window.ethereum);
    }
    try {
        await window.ethereum
            .request({
                method: "eth_requestAccounts",
            })
            .then((accounts) => {
                selectedAccount = accounts[0];
                window.web3.eth.personal.sign("Hello Maine, Please sign in.", selectedAccount)
                    .then(function(receipt){ 
                        signatureReceipt = receipt;
                        //if (signatureReceipt === undefined){return}
                        console.log("signature receipt: ", signatureReceipt, signatureReceipt!=null);
                        if(signatureReceipt!=null) {
                            localStorage.setItem("userAddress", selectedAccount);
                            alreadySignIn = true;
                            console.log('selectedAccount: ', selectedAccount, 'signed in - alreadySignIn: ', alreadySignIn); 
                            showAddress();  
                        }})            
            });      
    } catch (error) {
        console.error(error);
    } 
    console.log("alradySingIn: ", alreadySignIn)

    window.ethereum.on('accountsChanged', function(accounts) {
        if(alreadySignIn){
            console.log('alreadySignIn: ', alreadySignIn);
            selectedAccount = accounts[0];
            window.web3.eth.personal.sign("Hello Maine, account changed, please sign in again. ", selectedAccount)    //should first check if the account already have signature
                .then(function(receipt){ 
                    signatureReceipt = receipt;
                    //if (signatureReceipt === undefined){return}
                    console.log('signature receipt: ', signatureReceipt, signatureReceipt!=null);
                    if(signatureReceipt!=null) {
                        localStorage.setItem('userAddress', selectedAccount);
                        alreadySignIn = true;
                        console.log('selectedAccount changed to: ', selectedAccount);
                        showAddress();
                }})            
        } else {
            console.log("Already logout.");
        }
    })
}

function logout() {
    localStorage.userAddress = null;
    localStorage.removeItem("userAddress");
    alreadySignIn = false;
    signatureReceipt = null;
    showAddress();   
}


export default function LoginSwitch() {
    const [auth, setAuth] = useState(false);

    const handleLogInChange = (event) => {
        //Check if MetaMask installed?
        if (window.ethereum && window.ethereum._state.isUnlocked && window.ethereum.selectedAddress!=null) { 
            login();
            console.log("after login() alreadySignIn: ", alreadySignIn)
            setAuth(event.target.checked);

            console.log("event.target.checked, event.target.value, event.currentTarget: ", event.target.checked, event.target.value, event.currentTarget);
            console.log("window.ethereum: ", window.ethereum, window.ethereum!=null, window.ethereum._state.isUnlocked, window.ethereum.selectedAddress!=null);
        } else {
            console.log("Can Not Login the website. Please install, unlockMetaMask first.")
            console.log("window.ethereum: ", window.ethereum, window.ethereum!=null, window.ethereum._state.isUnlocked, window.ethereum.selectedAddress!=null);
        }
    };

    const handleLogOutChange = (event) => {
        logout();
        setAuth(false);
        console.log("alreadySignIn:  ", alreadySignIn)
    };
     
    return (
        <Toolbar>
            <Button variant="contained" onClick={login}> Login </Button>
            <Button variant="contained" onClick={logout}> Logout </Button>
            <FormGroup>
                <FormControlLabel sx={{justifyContent: "right"}}
                    control={
                        <Switch
                        checked={auth}
                        onChange={auth ? handleLogOutChange : handleLogInChange}
                        aria-label="login switch"
                        color="success"
                        />
                }
                    label={auth ? 'Logout' : 'Login'}
                />
            </FormGroup> 
        </Toolbar>   
    )
}
    