import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import LandscapeIcon from '@mui/icons-material/Landscape';
import FaceIcon from '@mui/icons-material/Face';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import DiamondIcon from '@mui/icons-material/Diamond';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import FlutterDashIcon from '@mui/icons-material/FlutterDash';
import { Typography, Grid } from '@mui/material';
import { makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        border: '3px',
        padding: '10px',
    },
    item: {
        padding: '10px',
        border: '1px ',
    },
}));

export default function CustomizedTimeline() {
  const classes = useStyles();

  return (
    <>
    <Grid container className={classes.container} spacing={2} mt='50px' id='timeline'>
      <Grid item className={classes.item} xs={12} textAlign="center">
        <Typography variant="h3" component="div" color='#5a6167'> Time Line </Typography>
      </Grid>
      <Grid item className={classes.item} xs={12} >
          <Timeline position="alternate" >
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                Dec 2023
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  <FilterVintageIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Release The Flowers & Birds Collection
                </Typography>
                <Typography> The first element of Land of Blossoms </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                Mar 2024
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                  <LandscapeIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Release The Landscape Collection
                </Typography>
                <Typography > The computer generated artworks based on freehand style landscape watercolor & ink paintings. </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                  sx={{ m: 'auto 0' }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                >
                  June 2024
                </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary" variant="outlined">
                  <FaceIcon />
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Release The LOB 2D Avatar Collection
                </Typography>
                <Typography> The mixture of past & future 2D avatars for Land of Blossoms </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                Sept 2024
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                <TimelineDot color="secondary">
                  <DiamondIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    Release The LOB 3D Jade Sculpture Collection
                </Typography>
                <Typography> The computer generated 3D art works based on 3D jade sculptures carved by talented jade artists. </Typography>
              </TimelineContent>
            </TimelineItem>
                  <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                Dec 2024
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  <ThreeDRotationIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Release The LOB 3D Avatar Collection
                </Typography>
                <Typography> The mixture of nature & hunman-like beings 3D avatars for Land of Blossoms.  </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                Mar 2025
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                <TimelineDot color="secondary">
                  <FlutterDashIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    Release The Land of Blossom Gallery
                </Typography>    
                <Typography> The digital LOB Gallery will exhibit and trade our aritists' artworks. </Typography>
              </TimelineContent>
            </TimelineItem>
              <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                June 2025
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  <EmojiNatureIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  Release LOB merchandises: clothing, accessories, collectables ...etc
                </Typography>
                <Typography> Create a market place to trade and license LOB artworks for merchandises. </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                2025 and on...
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  <EmojiNatureIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  TBC
                </Typography>
                <Typography> Continue to develope LOB in expending the artist network, generating art and comercializing art. </Typography>
              </TimelineContent>
            </TimelineItem>
        </Timeline>
      </Grid>
    </Grid>  
    </>
  );
}
