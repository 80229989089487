import React from 'react';
import styled from 'styled-components';

const PreLoader = (props) => {
  return (
    <LoaderWrapper className={ props.loading ? 'active' : 'disabled' }>
      <svg xmlns="http://www.w3.org/2000/svg" width="150px" height="150px" viewBox="0 0 150 150" fill="none">
        <g>
          <circle cx="75" cy="75" r="61" stroke="#7B87AF" strokeWidth="2"/>

        </g>
          <circle id="orbit" cx="14" cy="75" r="5" fill="#7B87AF"/>
      </svg>
    </LoaderWrapper>
  )
}

const LoaderWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  display: flex;
  pointer-events: none;

  &.active {
    opacity: 1;
    z-index: 1000;
  }

  &.disabled {
    animation: fadeOutAnim ease 1000ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes fadeOutAnim {
    0% {
      opacity: 1;
      visibility: visible;
    }
    95% {
      z-index: 1000;
    }
    100% {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }
  }


  circle#orbit {
    animation: 3s linear infinite orbit;
    transform-origin: center center;

    @keyframes orbit {
      from {
        transform: rotate(0deg);
      }
    
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export default PreLoader;


