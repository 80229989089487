import * as React from 'react';
import {Container, Accordion,  AccordionSummary, AccordionDetails, Typography, Grid} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function QA() {
  return (
    <>
    <Grid container spacing={2} id='q&a' >
      <Grid item xs={12} textAlign="center">
        <Typography variant="h3" component="div" color='#5a6167'> Q & A </Typography>
      </Grid>
    </Grid>
    <Container maxWidth="lg" >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant='h7' style={{ fontWeight: 500 }}>What is "Land of Blossoms"?</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Container fixed> 
                <Typography  style={{ wordWrap: "break-word" }}>
                    "Land of Blossoms" is collections of NFTs inspired by The Peach Blossom Spring,  
                    a fable written by Tao Yuanming in 421 about a chance discovery of an ethereal utopia 
                    where the people led an ideal existence in harmony with nature, unaware of the outside world for centuries.  
                    The peach blossom spring has become a symbol of an ideal world. 
                    The story inspired many later poems, paintings and music compositions. 
                    Land of Blossoms will have various building elements via different artworks, such as flowers and birds, landscape watercolor & ink paintings. 
                </Typography>
            </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant='h7' style={{ fontWeight: 500 }}>What is "Flowers & Birds collection"?</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Container fixed> 
                <Typography style={{ wordWrap: "break-word" }}>
                    The Flowers & Birds collection consists of 9700 computer generated artworks based on handmade watercolor & ink paintings. 
                    It is the first collection of Land of Blossoms. The artworks in this collection are freehand style. 
                    The first rule of freehand watercolor & ink painting is the use of connotation which is a kind of spiritual meaning. 
                    It focuses on the personality and the cooperation of every element in the painting. 
                    By depicting all the contents in a scene, the painter can finally achieve an atmosphere in which the relations, colors, positions 
                    and all the other elements are able to convey spiritual consideration. 
                </Typography>
            </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant='h7' style={{ fontWeight: 500 }}> What are the development plans for Land of Blossoms ? </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Container fixed> 
                <Typography style={{ wordWrap: "break-word" }}>
                    The team has acess to a large number of artists. We will act as incubator to help emerging artists to develope.
                </Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                    Artists can showcase and trade their artworks in Land of Blossoms platform and have access to finanical support from the team. 
                </Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                    More NFT collections in line with Land of Blossoms concept will be lauchned on our platform by our artists. 
                </Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                    We will try to commercialize LOB artworks in various sectors, such as licensing for fashion products, collectables, games.
                </Typography>
            </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant='h7' style={{ fontWeight: 500 }}> What are the benefits of holders of Land of Blossoms NFTs ? </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Container fixed> 
                <Typography style={{ wordWrap: "break-word" }}>
                    Holders of LOB NFTs will have previliages in the LOB's future events:
                </Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                    Whitelisted and airdropped for future NFT release, whitelisted for offline artwork sale, 
                    access for future artwork commercialization, access to LOB future online & offline events...etc. 
                </Typography>
            </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant='h7' style={{ fontWeight: 500 }}>What is the public sale price?</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Container fixed> 
                <Typography  style={{ wordWrap: "break-word" }}>
                    The Flowers & Birds NFT collection Public Sale minting prices:
                </Typography>
                <br/>
                <Typography  style={{ wordWrap: "break-word" }}>
                    Tier 1: 1 - 4850 NFTs, 0.07 Ether/each;
                </Typography>    
                <Typography  style={{ wordWrap: "break-word" }}>    
                    Tier 2: 4851 - 9700 NFTs, 0.08 Ether/each;
                </Typography>    
                <br/>
            </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant='h7' style={{ fontWeight: 500 }}>How to mint this collection's NFTs?</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Container fixed> 
                <Typography style={{ wordWrap: "break-word" }}>
                   The NFTs can be minted after minting started. For minting, please use desktop browsers with Metamask installed.  
                </Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                    1. Please install Metamask if the browser doesn't have it. Connect to Metamask Wallet by click yellow button `Connect to Metamask` on the top right corner of the home page, then follow the Metamask instructions.
                </Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                    2. Select the number of NFTs to mint by click `Select Number of NFTs to Mint`. The valid number to mint per Metamask wallet address: from 1 to 10. 
                </Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                    3. After select the number to mint, click `Confirm`.
                </Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                    4. The `Mint` button will be active and show the selected number to mint. click `Mint`
                </Typography>
                <Typography style={{ wordWrap: "break-word" }}>
                    5. Follow Metamask's instructions. 
                </Typography>
                <Typography style={{ wordWrap: "break-word", display: "inline-block", whiteSpace: "pre-line"}}>
                    6. After the minting completed, click `View Transaction` to see the transaction record in Ethscan; click `view on Opensea` to see the minted NFTs in 
                       your Opensea account with the same Metamask account address. (Note: It could take some time for the newly minted NFT to appear on your Opensea 
                       account.)
                </Typography>
                <Typography style={{ wordWrap: "break-word", display: "inline-block", whiteSpace: "pre-line"}}>
                  (Note: It could take some time for the newly minted NFT to appear on your Opensea 
                       account.)
                </Typography>
            </Container>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant='h7' style={{ fontWeight: 500 }}> Can I use mobile phone to mint the NFTs ? </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Container fixed> 
                <Typography style={{ wordWrap: "break-word" }}>
                    Unfortunately, currently you can only mint via landofblossoms.xyz on a desktop browser with Metamask installed.
                </Typography>
            </Container>
        </AccordionDetails>
      </Accordion>   
       <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant='h7' style={{ fontWeight: 500 }}> How can I resell the NFT ? </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Container fixed> 
                <Typography style={{ wordWrap: "break-word" }}>
                    You can view and resell the NFT in opensea.io after minting.
                </Typography>
            </Container>
        </AccordionDetails>
      </Accordion>    
    </Container>
    </>
  );
}
