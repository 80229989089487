import React, { useState, useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';
import css from '../utils/cssVariables';
import { Grid } from '@mui/material';
import AppLink from './AppLink';
import MintingBox from '../components/mintingPage/MintingBox';
import MintingBoxText from '../components/mintingPage/MintingBoxText';
import CountDownTimerBox from '../components/mintingPage/CountDownTimerBox';
import NavigationBar from './NavigationBar';
import home1 from '../assets/img/home1.png'; 
import home2 from '../assets/img/home2.png';

const Home = (props) => {
  const [isCountDownEnded, setIsCountDownEnded] = useState(false)
  const parentDiv = useRef();
  const imageAnimate = useRef();
  const h1Animate = useRef();
  const linkAnimate = useRef();

  useLayoutEffect( () => {
    let introSeq = gsap.timeline({});

      introSeq.fromTo([parentDiv.current],
        {left: "-100%"},
        {left: 0, duration: .5}
        )
        .delay(1)
        .from([imageAnimate.current], {
          x: "400%",
          duration: 1,
        })
        .from([h1Animate.current], {
          y: -100,
          duration: 0.5,
          autoAlpha: 0,
        }, "<25%")
        .from([linkAnimate.current], {
          y: -100,
          duration: 0.5,
          autoAlpha: 0,
        }, "<25%")
    }, []);
    
  const Module = styled.div`
    ${css.mobile} {
        width: 92vw;
        height: 92vw;
        margin-bottom: 0.1rem;
    }

    img {
        width: 35%;
    }  
    `; 

  return (
    <ContentWrapper id="home" ref={parentDiv}>
      <ContentLeft>
        <NavbarWrapper>
          <NavigationBar />
        </NavbarWrapper>
        <TextWrapper>
          <IntroH2 ref={h1Animate}>Land of Blossoms <br/>NFT Collections</IntroH2> 
          <AppLink ref={linkAnimate}/>
        </TextWrapper>

        <ImageWrapper>
          <img src={home1} alt=" home1 " onLoad={props.handleImgLoad}/>
        </ImageWrapper>
      </ContentLeft>

      <ContentRight>

        <Grid container ref={linkAnimate}>
          {isCountDownEnded
            ? (<MintingBoxText />)
            : (<CountDownTimerBox setIsCountDownEnded={setIsCountDownEnded}/>)
          }
        </Grid>
          <ImageWrapper2>
            <img src={home2} ref={imageAnimate} alt=" home2 " onLoad={props.handleImgLoad}/>
          </ImageWrapper2>
      
      </ContentRight>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.section`
  background-color: ${css.primary};
  width: 100%;
  height: 50vw;
  display: flex;
  flex-direction: row;
  margin-bottom: 15vw;
  padding-top: 10vh;
  will-change: transform;
  
  ${css.mobile} {
    position: relative;
    height: unset;
    display: flex;
    flex-direction: column;   
    justify-content: space-between;
  }
`;

const ContentLeft = styled.div`
  position: relative;
  width: 66.6666vw;
  height: 100%;
  padding-left: ${css.paddingLarge};
  
  ${css.mobile} {
    width: 100%;
  }
`;

const NavbarWrapper = styled.div`
  ${css.mobile} {
    overflow: hidden;
    width: 100%;
    height: 30vh;
    position: absolute;
    left: 0px;
    font-size: 3vw;
    line-height: 4.5vw;
  }
`;

const TextWrapper = styled.div`
  padding: 40px 0px 20vw ${css.paddingLarge};

  ${css.mobile} {
    padding-right: ${css.paddingSmall};
    padding-left: ${css.paddingSmall};
  }
`;

// font-size: 12.8vw;
// line-height: 12.8vw;  FFF
const IntroH2 = styled.h2`
  color: #5a6167;

  ${css.mobile} {
    font-size: 8.8vw;
    line-height: 8.8vw;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  bottom: -105px;
  max-width: 150%;

  ${css.mobile} {
    overflow: hidden;
    width: 100%;
    height: 30vh;
    position: absolute;
    left: 0px;
    bottom: -15vh;
  }

  & > img { 
    max-width: 100%;

    ${css.mobile} {
      position: absolute;
      bottom: 0vh;
    }  
  }
`;

//height: 100%;     bottom: -14.25vw;    -9.25vw;
const ImageWrapper2 = styled.div`
  position: relative;
  display: block;
  width: 33.3333vw;
  height: 35vh;
 
  & > img {
    position: absolute;
    right: 40px;
    bottom: -7.25vw;
  }
  
  ${css.mobile} {
    display: none;
  }
`;

const ContentRight = styled.div`
  position: relative;
  display: block;
  width: 33.3333vw;
  height: 100%;
  text-align: center;

  & > img {
    position: absolute;
    left: -80px;
    bottom: -14.25vw;
  }

  ${css.mobile} {
    width: 100%;
    padding: 0;
  }
`;

export default Home;


