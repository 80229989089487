import * as React from 'react';
import { Box, Slider, alpha, styled } from '@mui/material';

const NumberSlider = styled(Slider)(({ theme }) => ({
    width: 550,
    color: '#c66344', 
    '& .MuiSlider-thumb': {
        '&:hover, &.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 16px ${alpha('#b4b799', 0.16)}`,
        },
        '&.Mui-active': {
        boxShadow: `0px 0px 0px 28px ${alpha('#b4b799', 0.16)}`,
        },

        height: 16,
        width: 16,
        backgroundColor: '#c66344',  
    },
    '& .MuiSlider-valueLabel': {
        color: '#1b140e', 
    },
    '& .MuiSlider-valueLabelLabel': {
        backgroundColor: '#efc194', 
    },
    }));
    

export default function DiscreteSliderLabel(props) { 
    const handleChangeCommitted = (event, newValue) => {
        console.log(newValue);
        props.setMintAmount(newValue);  
    };

    return (
        <Box sx={{ width: 550 , height: 100}}>
            <NumberSlider
                aria-label="Always visible"
                defaultValue={1}
                step={1}
                marks={true}
                min={1}
                max={10}
                valueLabelDisplay="on"
                onChangeCommitted={handleChangeCommitted}
            />          
        </Box>
    );
}