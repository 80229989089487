import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link'
import styled from 'styled-components';
import { GlobalStyle } from './utils/cssGlobal';
import LoadingAnim from './components/PreLoader';
import Home from './components/Home';
import Intro from './components/Intro';
import Showcase from './components/Showcase';
import Team from './components/Team';
import Footer from './components/Footer';
import ShowcaseMasonry from './components/showcase/ShowcaseMasonry';
import TimeLine from './components/TimeLine'
import QA from './components/QA'
import * as images from './assets/img';


const imagesList = Object.values(images);

function App() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ count, setCounter ] = useState(0);

  useEffect(() => {
    if (count >= imagesList.length) {
      setIsLoading(false);
    }
  }, [count])

  const loadImages = () => {
    return imagesList.map( src => 
      <img
        key={src}
        src={src}
        alt="img preload"
        onLoad={() => setCounter( count + 1 )}
      />
    );
  };

    return (
      <main>
        <GlobalStyle />
          <LoadingAnim loading={isLoading} />

        {!isLoading ?
          <>
          <Router>  
            <BoxedContent>
              <Home />
              <Intro/>
              <Showcase />
              <ShowcaseMasonry/>
              <TimeLine />
              <Team />
              <QA />
            </BoxedContent>
            <Footer />  
          </Router>        
          </>
        : <ImgLoader>{loadImages()}</ImgLoader>  
        }
      </main> 
    )
}

const BoxedContent = styled.div`
  margin : 0 auto;
`;

const ImgLoader = styled.div`
  display: none;
  height: 0;
  width: 0;
  opacity: 0;
  transition: all ease-out 500ms;
`;

export default App;




