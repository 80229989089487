import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Grid } from '@mui/material';
import { makeStyles} from '@mui/styles';

const linkStyle = {
  //margin: "1rem", 
  //color: 'white', 
  textDecoration: "none",
  color: '#5a6167', 
};

const activeStyle = {
    color: 'blue',
}

const useStyles = makeStyles(() => ({
    container: {
        border: '0px',
        padding: '0px',
    },
    item: {
        padding: '1px',
        border: '0px ',
    },
}));

const NavigationBar = () => {
    const classes = useStyles();
    return(
              <Grid container className={classes.container} >
                <Grid item xs={2} className={classes.item}>
                    <HashLink to='#home' smooth style={linkStyle} activeStyle={activeStyle}>
                        Home
                    </HashLink>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <HashLink to='#intro' smooth style={linkStyle} activeStyle={activeStyle}>
                        Intro
                    </HashLink>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <HashLink to='#showcase' smooth style={linkStyle} activeStyle={activeStyle}>
                        Showcase
                    </HashLink>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <HashLink to='#timeline' smooth style={linkStyle} activeStyle={activeStyle}>
                        Timeline
                    </HashLink>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <HashLink to='#team' smooth style={linkStyle} activeStyle={activeStyle}>
                        Team
                    </HashLink>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <HashLink to='#q&a' smooth style={linkStyle} activeStyle={activeStyle}>
                        Q&A
                    </HashLink>
                </Grid>
              </Grid>
    )
}

export default NavigationBar;