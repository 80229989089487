import React, { useEffect, useState } from "react";
import { Grid, styled as styled2, Paper, ButtonBase } from '@mui/material';
import MintingPageTest from './MintingPageTest';
import image from '../../assets/img/showcase19.png';
import MetaMaskAuth from "./MetaMaskAuth";
import LoginSwitch from "./LoginSwitch";
import ConnectWallet from "./ConnectWallet";

export default function MintingBox() {
    return (
        <>
        <Paper
                sx={{
                    p: 10,
                    mt: 1,
                    maxWidth: 600,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#acc0c7',
                }}
                elevation={12}
                style={{
                    padding: 50,
                    border: "1.5px solid silver",
                    borderRadius: "5px",
                }}
        >
            <Grid container direction="column" spacing={0}>
                    <Grid item xs={12}>
                        <MetaMaskAuth onAddressChanged={address => {}}/> 
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonBase sx={{ width: 256, height: 256, display:'col' }}>
                            <Img alt="Mint" src={image} />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12}>
                        <MintingPageTest />
                    </Grid>
            </Grid> 
        </Paper>  
        </>   
  );
}

const Img = styled2('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const ConnectWalletButton = styled2('button') ({
        borderRadius: 50,
        backgroundColor: "#7b928f",
        padding: "12px 24px",
        fontSize: "16px",
        color: "#e9e9e5",
        cursor: "pointer",
});


// theme.palette.mode === 'dark' ? '#1A2027' : '#95adaa',

/**
  <>
        <Paper
            sx={{
                p: 10,
                mt:1,
                maxWidth: 600,
                flexGrow: 1,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#95adaa',
            }}
        >
            <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                    <Button variant="contained" color="inherit"> Connect Wallet </Button>
                </Grid>
                <Grid item xs={12}>
                    <ButtonBase sx={{ width: 256, height: 256, display:'col' }}>
                        <Img alt="Mint" src={image} />
                    </ButtonBase>
                </Grid>
                <Grid item xs={12}>
                    <MintingPageTest />
                </Grid>
            </Grid> 
        </Paper>  
    </>   
*/

/**
 <MetaMaskAuth onAddressChanged={address => {}}/> 

 <MintingPageTest />
*/