import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from 'styled-components';
import css from '../utils/cssVariables';
import showcase1 from '../assets/img/sct1.png'; 
import showcase8 from '../assets/img/showcase8.png'; 
import showcase15 from '../assets/img/showcase15.png'; 

const ContentWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: ${css.paddingLarge};
  padding-left: ${css.paddingLarge};
  
  ${css.mobile} {
    margin-top: 30%;
    height: 50vh;
    flex-direction: column;
    padding-right: ${css.paddingSmall};
    padding-left: ${css.paddingSmall};
  }
`;

const ContentLeft = styled.div`
  position: relative;
  flex: 1 1 0%;

  h2, p {
    white-space: pre-line;
  }
`;

const TextWrapper = styled.div`

`;

const ContentRight = styled.div`
  position: relative;
  flex: 1.5 1 0%;
  height: 100%;
  padding-left: ${css.paddingLarge};

  ${css.mobile} {
    display: block;
    width: 100%;
    margin-top: 20%;
  }
`;

//width: 31vw;  left: -27%;
const Img2Wrapper = styled.div`
  position: absolute;
  left: -20%;
  bottom: -7%;
  width: 20vw;
  padding-left: ${css.paddingLarge};

  ${css.mobile} {
    display: none;
  }
`;

const Img3Wrapper = styled.div`
  position: absolute;
  top: -36%;
  right: -2%;
  width: 46%;
  filter: drop-shadow(rgba(0,0,0,0.3) -25px 25px 36px);
  
  
  ${css.mobile} {
    left: 30%;
    top: -20%;
  }
`;

// ${css.mobile} {  width: 100vw;
const Img1Wrapper = styled.div`
  width: 50vw;
 
  ${css.mobile} {
    width: 67vw;
  }

`;

const Showcase = () => {

  gsap.registerPlugin(ScrollTrigger);

  const image1Animate = useRef();  
  const image2Animate = useRef();
  const image3Animate = useRef();
  const h2Animate = useRef();
  const pAnimate = useRef();
  
  useEffect(() => {

    let sequence = gsap.timeline({
      scrollTrigger: {
        trigger: "#Showcase",
        start: "top center",
      }
    });
    
    sequence.from( [h2Animate.current], {
      y: -200,
      duration: 0.75,
      autoAlpha: 0,
    })
    .from( [image1Animate.current], {
      x: "70vw",
      duration: 0.5,
    }, "<" )
    .from( [image2Animate.current], {
      x: "-10vw",
      duration: 0.5, 
      autoAlpha: 0,
    }, "<75%" )
    .from( [pAnimate.current], {
      y: -100,
      duration: 0.5,
      autoAlpha: 0,
    }, "<" )
    .from( [image3Animate.current], {
      y: -100,
      duration: 0.5,
      autoAlpha: 0,
    }, "<25%" )
  });

  return (
    <ContentWrapper >
      <ContentLeft>
        <TextWrapper id="Showcase">
          <h2 ref={h2Animate}>Showcase</h2>
          <p ref={pAnimate}>Flowers & Birds collection consists of <br/>9700 computer generated artworks <br/>based on freehand style <br/>watercolor & ink paintings. </p>
          <p ref={pAnimate}>The freehand watercolor & ink painting <br/>emphasizes the semblance in the <br/>spiritual aspect. </p>
          <p ref={pAnimate}>This kind of artwork does not chase for <br/>the physical similarity and <br/>the cooperation of reality. </p>
          <p ref={pAnimate}>It might be the essence of freehand style <br/>that it mostly grasps the most obvious <br/>or essential spiritual characteristics. </p>
          <p ref={pAnimate}>The techniques of freehand style <br/>watercolor & ink painting are <br/>derived from nature. </p>
        </TextWrapper>
      </ContentLeft>

      <ContentRight>
        <Img1Wrapper ref={image1Animate}>
          <img src={showcase1} alt="image1"/>
        </Img1Wrapper>
        <Img2Wrapper ref={image2Animate}>
          <img src={showcase8} alt="image2"/>
        </Img2Wrapper>
        <Img3Wrapper ref={image3Animate} id='showcase'>
          <img src={showcase15} alt="image3"/>
        </Img3Wrapper>
      </ContentRight>
    </ContentWrapper>
  );
};

export default Showcase;