import React, { useRef, useState, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from 'styled-components';
import css from '../utils/cssVariables';
import arrowLeft from '../assets/svg/arrow-left.svg';
import arrowRight from '../assets/svg/arrow-right.svg';
import showcase10 from '../assets/img/showcase10.png';
import showcase13 from '../assets/img/showcase13.png';
import showcase22 from '../assets/img/imageSlider4.png';

const slides = [
  {
    content: 'Flowers',
    type: 'Watercolor & Ink Painting',
    note: "",
    img: showcase13,
  },
  {
    content: 'Birds',
    type: 'Watercolor & Ink Painting',
    note: "",
    img: showcase22,
  },
];

const ImageSlider = () => {

  gsap.registerPlugin(ScrollTrigger);

  const imgAnimate = useRef();
  const controlsAnimate = useRef();
  const textAnimate = useRef();
  const dataAnimate = useRef();

  useLayoutEffect( () => {
    let seqSlider = gsap.timeline({
      scrollTrigger: {
        trigger: "#ImageSlider",
        start: "top center",
      }
    });
    
    seqSlider.from([imgAnimate.current], {
      x: "30vw",
      autoAlpha: 0,
      duration: 0.5,
      delay: 0.5,
    })
    .from([textAnimate.current], {
      y: "50px",
      autoAlpha: 0,
      duration: 1,
      delay: 0.2,
    }, "<0.5")
    .from([dataAnimate.current], {
       x: "-30vw",
      autoAlpha: 0,
      duration: 1,
      delay: 0.2,
    }, "<0.5")
    .from([controlsAnimate.current], {
      y: "-20px",
      autoAlpha: 0,
      duration: 1,  
      delay: 0.2,    
    }, "<0")
  }, []);

  const [ slideIndex, setIndex ] = useState(0); 

  const increaseIndex = () => {
    if ( slideIndex < slides.length -1  ) {
      setIndex(slideIndex + 1);
    } else {
      setIndex(0);
    }
  }
  
  const decreaseIndex = () => {
    if ( slideIndex > 0 ) {
      setIndex(slideIndex - 1);
    } else {
      setIndex(slides.length -1);
    }
  }

  return (
    <ContentWrapper id="ImageSlider" >
      <Controls ref={controlsAnimate}>
        <button id="prev" onClick={ () => decreaseIndex() }><img src={arrowLeft} alt="arrow for returning slider"/></button>
        <button id="next" onClick={ () => increaseIndex() }><img src={arrowRight} alt="arrow for advancing slider"/></button>
      </Controls>
      <Slider>
        <div key={slides[slideIndex].model} className="slide">
          <TextWrapper ref={textAnimate}>
            <h4>{slides[slideIndex].content}</h4>
            <p>{slides[slideIndex].type}</p>
            <DataBox ref={dataAnimate}>
              <div>
                <div className="data" ><p>{slides[slideIndex].note}</p></div>
              </div>
            </DataBox>
          </TextWrapper>
         
          <ImgWrapper ref={imgAnimate} >
            <img src={slides[slideIndex].img} alt=""  />
          </ImgWrapper>
        </div>
      </Slider>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Controls = styled.div`
  display: block;
  position: absolute;
  width: 66%;
  text-align: right;

  ${css.mobile} {
    width: 100%;
  }

  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    width: 2rem;
    margin: 1rem;
  }
  
  img {
    display: inline-block;
    height: auto;
    width: 100%;
  }
`;

const Slider = styled.div`
  display: flex;
  flex-direction: row;
`;

//color: #535454  
const TextWrapper = styled.div`
  font-family: 'Bebas Neue', sans-serif;
  padding-top: 5vw;
  padding-left: 5vw;

  h4 {
    color: #7c8084;
  }
  
  p { 
    color: #7c8084;
    font-size: 1vw;

    ${css.mobile} {
      font-size: 3.5vw;
    }
  }
`;

const DataBox = styled.div`
  
  & > div {
    display: flex;
    align-content: center;
    align-items: center;

    * {
      box-sizing: border-box;
      width: auto;
      padding: 0;
    }

    img {
      padding-right: 0;
      max-width: 25px;
    }
    
    .label {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 1.1rem;
      width: 50px;
    }
    
    .data {
      font-size: 2.25rem;
      padding-left: 1rem;

      ${css.mobile} {
        font-size: 7vw;
      }
    }
  }
`;

const ImgWrapper = styled.div`
  position: absolute;
  bottom: -10%;
  left: 15%;
  width: 100%;

  ${css.mobile} {
    img {
      width: 80%;
    }  
`;

export default ImageSlider;

/**
<img src={showcase10} alt="note" />
<div className="label"><p></p></div>
*/