import React from 'react';
import { useState, useEffect } from 'react';
import { Typography,  styled as styled2, Grid, FormGroup, FormControlLabel, Switch,  Container, CssBaseline } from '@mui/material';
import {TextField, Dialog,  DialogActions, DialogContent, DialogContentText } from '@mui/material';
import contract from '../mint/smart_contract_json/contract_v5.json';
import SelectMintAmount from "./SelectMintAmount"
import SwipeableTextMobileStepper from "./SwipeableTextMobileStepper"
import Web3 from 'web3';
import { ethers } from 'ethers';
import DiscreteSliderLabel from './DiscreteSliderLabel';
//import TextFieldNumberInput from './TextFieldNumberInput';
import image from '../../assets/img/showcase19.png';
import MetaMaskAuth from "./MetaMaskAuth";

const initialInfo = {
  connected: false,
  status: null,
  account: null,
  contract: null,
}

const chainScan_txHash_base_uri = "https://rinkeby.etherscan.io/tx/";
const tokenId = [];

const MintingPageTest = () => {
  const [info, setInfo] = useState(initialInfo);
  const [mintAmount, setMintAmount] = useState(0);
  const [transactionHash, setTransactionHash] = useState(null);
  const [currentMintingCostEther, setCurrentMintingCostEther] = useState(null);

  const [isPublicSaleActive, setIspublicSaleActive] = useState(null);
  const [isPaused, setIsPaused] = useState(null);
  const [currentSupply, setCurrentSupply] = useState(1);
  const [currentAccountMintedBalance, setCurrentAccountMintedBalance] = useState(null);

  const ownerAddress = contract.ownerAddress;
  const maxSupply = contract.maxSupply;
  const maxMintAmountPerAccount = contract.maxMintAmountPerAccount;
  const creatorReservedAmount = contract.creatorReservedAmount;

  console.log("info: ", info);
  
  const init = async() => {
      if (window.ethereum && window.ethereum._state.isUnlocked) {
          const accounts = await window.ethereum.request({
                                method: "eth_requestAccounts"});   
          const networkId = await window.ethereum.request({
                                method: "net_version",});
          if (networkId == 4 ) {
                      let web3 = new Web3(window.ethereum);
                      setInfo({
                        ...initialInfo, 
                        connected: true,
                        account: accounts[0],
                        contract: new web3.eth.Contract(contract.abi, contract.address),
                        }); 
                        //const hash = web3.utils.keccak256(web3.eth.abi.encodeParameters(['string', 'string'], ['LOB', '2']))
                        const hash = web3.utils.soliditySha3( { type: "string", value: 'LOB' }, { type: "string", value: '1' })
                        const hex_num = web3.utils.hexToNumberString(hash)
                        const hex_num_fixed = hex_num.substr(hex_num.length - 7, hex_num.length)
                        console.log("hash, hex_num, hex_num_fixed: ", hash, hex_num, "...", hex_num_fixed)
                        const ethers_hash = ethers.utils.solidityKeccak256(['string','string'], ['LOB', '1'])  
                        const ethers_hash_num = ethers_hash
                        console.log("ethers_hash, ethers_hash_num: ", typeof(ethers_hash), ethers_hash, "...", ethers_hash_num)
          }
          if (networkId != null & networkId != 4) {
                  alert("Please switch to Ethereum Rinkeby Testnet!");
          }
      }              
  };

  const initOnChanged = () => {
      if(window.ethereum){
        window.ethereum.on("accountsChanged", () => {
          window.location.reload();
        });
        window.ethereum.on("chainChanged", () => {
          window.location.reload();
        })
      }
  };

  useEffect(() => {
      init();
      initOnChanged();
  }, []);

  
  const mint = async (toMintAmount) => {
      console.log( "form: ", info.account, "toMintAmount: ", toMintAmount, "mintAmount: ", mintAmount, currentMintingCostWei)
      const currentMintingCostWei = Web3.utils.toWei(currentMintingCostEther, 'ether')
      const gasLimitWei = 3000000; 
      let totalCostWei = String(currentMintingCostWei * toMintAmount);
      let totalGasLimitWei = String(gasLimitWei * toMintAmount);

      info.contract.methods
      .mint(toMintAmount)
      .send({
        from: info.account,
        to: contract.address,
        value: totalCostWei,
        gasLimit: totalGasLimitWei
      })
      .on('transactionHash', function(hash){
            console.log("transactionHash: ", hash);
            setTransactionHash(hash);
        })
      .on('receipt', function(receipt){
           console.log("receipt: ", receipt)
        })
      .on('confirmation', function(confirmationNumber, receipt){
           console.log("confirmationNumber, receipt: ", confirmationNumber, receipt)
        })
      .on('error', function(error, receipt) {
            console.log("error, receipt: ", error, receipt)
        })
      .catch((err) => {
        console.log(err.message);
      })
      setMintAmount(0);
  }


  const initACData = {
    connected: false,
    status: null,
  }

   async function accountCompliance (_accountAddress) {
        accountComplianceData(_accountAddress);

        if(isPublicSaleActive == false) {
          alert("Public Sale must be active to mint.")
        }
        if(isPaused == true){
          alert("Sale is currently paused.")
        } 
        if(currentSupply == maxSupply){
          alert("Max supply of this collection exceeded.") 
        }
        if(currentAccountMintedBalance == maxMintAmountPerAccount){
          alert("Max mint amount per account exceeded.") 
        }
  }

   async function accountComplianceData (_accountAddress) {
          info.contract.methods
          .publicSaleActive()
          .call()
          .then((res) => {
            setIspublicSaleActive(res);
          })
          .catch((err) => {
            console.log(err)
          })

          info.contract.methods
          .paused()
          .call()
          .then((res) => {
            setIsPaused(res);
          })
          .catch((err) => {
            console.log(err)
          })

          info.contract.methods
          .totalSupply()
          .call()
          .then((res) => {
            setCurrentSupply(res);
          })
          .catch((err) => {
            console.log(err)
          })

          info.contract.methods
          .accountMintedBalance(_accountAddress)
          .call()
          .then((res) => {
            setCurrentAccountMintedBalance(res);
          })
          .catch((err) => {
            console.log(err)
          })
  }

  // get transacion at Rinkeby.ethscan by transactionHash
  const getTransctionFromChainScan = () => {
    const URL  = chainScan_txHash_base_uri + transactionHash;
    window.open(URL, '_blank');
  }

  const getMintedNFTByAcccountFromOpensea = () => {
      URL = 'https://testnets.opensea.io/'+info.account
      window.open(URL, '_blank');  
  }

  const TextFieldNumberInput = () => {
      const [value, setValue] = useState(0);
      const min = 0;
      const max1 = 10;
      const max2 = maxMintAmountPerAccount - currentAccountMintedBalance;
      const max3 = maxSupply - creatorReservedAmount - currentSupply;
      const max = Math.min(max1, max2, max3);
      const price_eth = Number(currentMintingCostEther);

      const currentMintingCost = async () => {
        info.contract.methods
          .currentMintingCost(currentSupply)
          .call()
          .then((res) => {
            const etherValue = Web3.utils.fromWei(res, 'ether');
            setCurrentMintingCostEther(etherValue);
          })
          .catch((err) => {
            console.log(err);  
          })
      }

      const handleChange = (e) => {
          var value = parseInt(e.target.value, 10);

          if (value > max) value = max;
          if (value < min) value = min;

          setValue(value);
          totalCost();
          console.log("value: ", value);
      }

      const handleClick = () => {
          setMintAmount(value)
          console.log("mintAmount: ", mintAmount, "value: ", value)
      }

      const totalCost = () => {
           currentMintingCost(currentSupply);
           console.log("price_eth: ", price_eth)
           const totalCost = (price_eth * value).toFixed(2)
           return totalCost
      }
    
      return (
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
                <TextField
                    type="number"
                    inputProps={{ min, max }}
                    sx={{width: 100}}
                    defaultValue="1"
                    value={value}
                    onChange={handleChange}
                />
            </Grid>
             <Grid item xs={12}>
                <Typography mt="7px" > Confirm to mint {value} NFTs, total cost is {totalCost()} ETH. </Typography>  
             </Grid>
            <Grid item xs={12}>
                <ConfirmButton variant="contained" disabled={value==0} onClick={handleClick}> Comfirm </ConfirmButton>
            </Grid>
        </Grid>
      );
  }

  const FormDialog = () => {
      const [open, setOpen] = React.useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };

      if(info.account != null){
          accountCompliance(info.account);
          console.log("accoountCompliance 4 data: ", isPublicSaleActive, isPaused, currentSupply, currentAccountMintedBalance );
      }

      // disabled={info.connected==false}
      return (
        <div>
                <Grid container direction="col" justifyContent="center" >
                    <SeletcNumberButton variant="contained" disabled={info.connected==false} onClick={handleClickOpen}> 
                        Select Number of NFTs to Mint
                    </SeletcNumberButton>
                    <Dialog open={open} >
                        <img
                            src={image}
                            alt="Mint"
                            style={{ borderRadius: 5 }}
                        />
                        <DialogContent >
                            <Grid container direction="column" alignItems="center" spacing={{md:5}} >
                                <Grid item xs={12}>
                                    <DialogContentText textAlign="center">
                                        <Grid container direction="col" spacing={{md:1}} >
                                            <Grid item xs={12}>
                                                <Typography color='#1b140e'> Please select the number of NFTs to mint </Typography>
                                            </Grid>  
                                            <Grid item xs={12} >
                                                <TextFieldNumberInput />
                                            </Grid>
                                        </Grid>
                                    </DialogContentText>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={{md:1}} direction="column" alignItems="center" justifyContent="center" mb="10px" >
                                <Grid item xs={6} ml={0.8} >
                                    <ConfirmButton variant="contained"  onClick={handleClose}> Close </ConfirmButton>
                                    <Typography color='#1b140e'> Minted :  {currentSupply} / {maxSupply} </Typography>
                                </Grid>   
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </Grid>
        </div>
      );
    }

  return (
    <>
    <CssBaseline />
    <Container maxWidth="sm">
            <Grid item xs={12}>
               
            </Grid>
            <br />
            <FormDialog  />
            <br />
            <Grid container direction="row"  justifyContent="center"  spacing={{md:3}} >
                                <Grid item xs={12} textAlign="center">
                                    <MintButton variant="contained"  disabled={mintAmount==0}  onClick = {() => mint(mintAmount)}> Mint {mintAmount} </MintButton>
                                </Grid>
                                <Grid item xs={6} >
                                    <ViewButton variant="contained" disabled={info.connected==false}   onClick = {() => getTransctionFromChainScan()}> View Transaction </ViewButton>
                                </Grid>
                                <Grid item xs={6} >
                                    <ViewButton variant="contained" disabled={info.connected==false}  onClick = {() => getMintedNFTByAcccountFromOpensea()}> View on Opensea </ViewButton>
                                </Grid>                     
            </Grid>
    </Container>
    </>
  )
}

//  backgroundColor:#7b928f      color: #e9e9e5  
const MintButton = styled2('button') ({
        borderRadius: 20,
        backgroundColor: "#51626f",
        padding: "12px 24px",
        fontSize: "18px",
        color: "#e9e9e5",
        cursor: "pointer",
});

const SeletcNumberButton = styled2('button') ({
        borderRadius: 20,
        backgroundColor: "#51626f",
        padding: "12px 24px",
        marginBottom: "10px", 
        fontSize: "16px",
        color: "#e9e9e5",
        cursor: "pointer",    
});

const ViewButton = styled2('button') ({
        borderRadius: 20,
        backgroundColor: "#51626f",
        padding: "12px 24px",
        fontSize: "14px",
        color: "#e9e9e5",
        cursor: "pointer",
});

const ConfirmButton = styled2('button') ({
        borderRadius: 50,
        backgroundColor: "#c66344",
        padding: "12px 24px",
        fontSize: "16px",
        color: "#e9e9e5",
        cursor: "pointer",
});

const CloseButton = styled2('button') ({
        borderRadius: 50,
        backgroundColor: "#c66344",
        padding: "12px 24px",
        fontSize: "16px",
        color: "#e9e9e5",
        cursor: "pointer",
});

const ConnectWalletButton = styled2('button') ({
        borderRadius: 50,
        backgroundColor: "#7b928f",
        padding: "12px 24px",
        fontSize: "16px",
        color: "#e9e9e5",
        cursor: "pointer",
});

export default MintingPageTest;




/**
  const address1 = "0x2e8aA26555Fa935cA841B55c174AB67f11B8E79D"
  const address2 = "0xb9956B1699F6FF0e11FdC8b650dF2f91704c49E4"
  const address3 = "0x4e8929Ba8f1fa15b6048d0bFcd07357cbBb772Cb"
  const address4 = "0x209c123F8ed1e2E613586CcEe4458B9731A59bA0"
  const address5 = "0x2572c5a860FFbe215a43357356eFd64D37C35759"
  const selectedAddress = address1

  // Call
  const isWhitelisted = async (selectedAddress) => {
    info.contract.methods
      .isWhitelisted(selectedAddress)
      .call()
      .then((res) => {
        console.log("Result1: ", res)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  // Write
  const removeFromWhitelist = async (selectedAddress) => {
    info.contract.methods
      .removeFromWhitelist(selectedAddress)
      .send({from: '0x2572c5a860FFbe215a43357356eFd64D37C35759'})
      .on('transactionHash', function(hash){
            console.log("transactionHash: ", hash);
            setTransactionHash(hash);
        })
      .on('receipt', function(receipt){
           console.log("receipt: ", receipt)
        })
      .on('confirmation', function(confirmationNumber, receipt){
           console.log("confirmationNumber, receipt: ", confirmationNumber, receipt)
        })
      .on('error', function(error, receipt) {
            console.log("error, receipt: ", error, receipt)
        })
      .catch((err) => {
        console.log(err);
      })
  }

  const addToWhitelist = async (selectedAddress) => {
    info.contract.methods
      .addToWhitelist(selectedAddress)
      .send({from: '0x2572c5a860FFbe215a43357356eFd64D37C35759'})
      .on('transactionHash', function(hash){
            console.log("transactionHash: ", hash);
            setTransactionHash(hash);
        })
      .on('receipt', function(receipt){
           console.log("receipt: ", receipt)
        })
      .on('confirmation', function(confirmationNumber, receipt){
           console.log("confirmationNumber, receipt: ", confirmationNumber, receipt)
        })
      .on('error', function(error, receipt) {
            console.log("error, receipt: ", error, receipt)
        })
      .catch((err) => {
        console.log(err);
      })
  }
*/

/**
  const Item = styled2(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
    }));

  const MediaCard = () => {
      return(
            <>
            <CheckWalletConnected />
            <Card sx={{ maxWidth: 690 }}>
            <CardHeader
                avatar={
                <Avatar sx={{ bgcolor: blue[200] }} aria-label="Account">
                </Avatar>
                }
                title = {info.account}
                subheader= ""
            />
            <SwipeableTextMobileStepper />
            <CardContent>
                <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid Item xs={12} textAlign='center'>
                        <Typography sx={{variant: "h3", bgcolor: green[200], mb:5}}>Public Sale Started, Please mint the NFTs. </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>The Ink Painting NFT collections is on the Ethereum blockchain</Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item> Collection Max supply: 1000</Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>Public sale minting price per NFT: 0.03 ETH</Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>Max amount to mint per account: 20</Item>
                    </Grid>
                    <Grid item xs={12} >
                          <DiscreteSliderLabel setMintAmount={setMintAmount} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{justifyContent: "center"}}>
               <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid Item xs={12} textAlign='center'>
                        <Button variant="contained" size="large" sx={{bgcolor: blue[400]}} onClick = {() => mint(info.account, mintAmount)} >Mint</Button>
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        <Button id="scan" variant="outlined" size="small" sx={{bgcolor: green[200]}} onClick = {() => getTransctionFromChainScan()}> View Transaction </Button>
                    </Grid>
                    <Grid item xs={12} textAlign='center'>  
                        <Button  id="opensea" variant="outlined" size="small" sx={{bgcolor: green[200]}} onClick = {() => getMintedNFTByAcccountFromOpensea()}> View NFTs on Opensea </Button>
                    </Grid> 
                </Grid>   
            </CardActions>
            </Card>
            </>
    )
  }

*/

/***
                    <Grid item xs={6}>
                        <SelectMintAmount setMintAmount={setMintAmount}  /> 
                    </Grid>
                    <Grid item xs={6}>  
                        <Paper sx={{variant: "outlined", mr: 15,  mt:2.5, textAlign: "center", bgcolor: blue[100]}}>
                            Mint Amount: {mintAmount}       
                        </Paper>
                    </Grid>                

*/

/**
<DiscreteSliderLabel setMintAmount={setMintAmount} />
*/

/**

        <br/>
        <br/>

        <div>
            <Button variant="contained" onClick = {() => isWhitelisted(selectedAddress)}> isWhitelisted"{selectedAddress}" </Button>  
        </div>
        <div>
            <Button onClick = {() => removeFromWhitelist(selectedAddress)}> removeFromWhitelist"{selectedAddress}" </Button>  
        </div>
        <div>
            <Button onClick = {() => addToWhitelist(selectedAddress)}> addToWhitelist"{selectedAddress}" </Button>  
        </div>
        <div>
            <Button onClick = {() => owner()}> owner is "{owner}" </Button>  
        </div>
        <div>
            <Button onClick = {() => {console.log(info)}}> info </Button>  
        </div>
  

*/

