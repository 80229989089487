import React, { useEffect, useState } from "react";
import { Grid, styled as styled2, Paper, ButtonBase } from '@mui/material';
import styled from 'styled-components';
import css from '../../utils/cssVariables';  
//import MintingPageTest from './MintingPageTest';
import image from '../../assets/img/showcase19.png';
//import MetaMaskAuth from "./MetaMaskAuth";
//import LoginSwitch from "./LoginSwitch";
//import ConnectWallet from "./ConnectWallet";

export default function MintingBox() {
    return (
        <>
        <Paper
                sx={{
                    p: 10,
                    mt: 1,
                    maxWidth: 350,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#acc0c7',
                }}
                elevation={12}
                style={{
                    padding: 30,
                    border: "1.5px solid silver",
                    borderRadius: "5px",
                }}
        >
            <Grid container direction="column" spacing={0}>
                    <Grid item xs={12}>
                        <ButtonBase sx={{ width: 256, height: 256, display:'col' }}>
                            <Img alt="Mint" src={image} />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12}>
                        <TextWrapper>
                            <IntroH2 >Land of Blossoms <br/> Minting is coming ...</IntroH2> 
                        </TextWrapper>
                    </Grid>
            </Grid> 
        </Paper>  
        </>   
  );
}

const Img = styled2('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const TextWrapper = styled.div`
  padding: 10px 0px 5vw ${css.paddingSmall};

  ${css.mobile} {
    padding-right: ${css.paddingSmall};
    padding-left: ${css.paddingSmall};
  }
`;

// font-size: 12.8vw;
// line-height: 12.8vw;  FFF   #5a6167
const IntroH2 = styled.h2`
  color: #FFF;
  font-size: 2vw;
  line-height: 2vw;

  ${css.mobile} {
    font-size: 8.8vw;
    line-height: 8.8vw;
  }
`;

