import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
//import Masonry from '@mui/lab/Masonry';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { styled as styled2 , Container } from '@mui/material';
import styled from 'styled-components';
import css from '../../utils/cssVariables';
import showcase13 from '../../assets/img/sc1.png';
import showcase14 from '../../assets/img/sc2.png';
import showcase16 from '../../assets/img/sc3.png';
import showcase17 from '../../assets/img/sc4.png';
import showcase18 from '../../assets/img/sc5.png';
import showcase19 from '../../assets/img/sc6.png';
import showcase20 from '../../assets/img/sc8.png';
import showcase22 from '../../assets/img/sc7.png';
import showcase23 from '../../assets/img/sc9.png';

const Label = styled2(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const Module = styled.div`
  ${css.mobile} {
    width: 92vw;
    height: 92vw;
    margin-bottom: 0.1rem;
  }

  img {
    width: 35%;
  }  
`;

export default function ShowcaseMasonry() {
  return (
    <Container maxWidth="sm" id='showcase'>
      <Box sx={{ width: 1000, minHeight: 500 }}>
            <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry >
                    {itemData.map((item, index) => (
                        <div key={index}>
                            <Module className="module">
                                <Label>{item.title}</Label>
                                <img
                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                    style={{
                                    borderBottomLeftRadius: 4,
                                    borderBottomRightRadius: 4,
                                    display: 'block',
                                    width: '100%',
                                    }}
                                />
                            </Module>
                        </div>
                    ))}
                </Masonry>
            </ResponsiveMasonry>
        </Box>
    </Container>
  );
}

const itemData = [
  {
    img: showcase13,
    title: ' ',
  },
  {
    img: showcase14,
    title: ' ',
  },
  {
    img: showcase23,
    title: ' ',
  },
  {
    img: showcase16,
    title: ' ',
  },
  {
    img: showcase17,
    title: ' ',
  },
  {
    img: showcase18,
    title: ' ',
  },
  {
    img: showcase19,
    title: ' ',
  },
  {
    img: showcase20,
    title: ' ',
  },
  {
    img: showcase22,
    title: ' ',
  },
];


