export const showcase1 = require('./showcase1.png')
export const showcase2 = require('./showcase2.png')
export const showcase3 = require('./showcase3.png')
export const showcase4 = require('./showcase4.png')
export const showcase5 = require('./showcase5.png')
export const showcase6 = require('./showcase6.png')
export const showcase7 = require('./showcase7.png')
export const showcase8 = require('./showcase8.png')
export const showcase9 = require('./showcase9.png')
export const showcase10 = require('./showcase10.png')
export const showcase11 = require('./showcase11.png')
export const showcase12 = require('./showcase12.png')
export const showcase13 = require('./showcase13.png')
export const showcase14 = require('./showcase14.png')
export const showcase15 = require('./showcase15.png')
export const showcase16 = require('./showcase16.png')
export const showcase17 = require('./showcase17.png')
export const showcase18 = require('./showcase18.png')
export const showcase19 = require('./showcase19.png')
export const showcase20 = require('./showcase20.png')
export const showcase21 = require('./showcase21.png')
export const showcase22 = require('./showcase22.png')
export const showcase23 = require('./showcase23.png')
export const showcase24 = require('./showcase24.png')


//export const carAudiA7 = require('./car-A7-MY18.png')
//export const carBenz = require('./car-Benz.png')
//export const carRangeRover = require('./car-Range-Rover-Velgar.png')
//export const carPorsche = require('./car-top-porsche-model.png')
//export const conJour2Map = require('./conJour2-map.png')
//export const conJour2Phone = require('./conJour2-phone.png')
//export const conJour2Car = require('./conJour2-Porsche-side.png')
//export const conJour3Phone = require('./conJour3-phone-with-shadow.png')
//export const conJour3Car = require('./conJour3-Porsche-front.png')
//export const introPhone = require('./intro-cars-group-device.png')
//export const introCarGroup = require('./intro-cars-group.png')
//export const introPhoneShadow = require('./intro-phone-with-shadow.png')
//export const propInsurance = require('./prop-insurance.png')
//export const propMaintenance = require('./prop-maintenance.png')
//export const propPricing = require('./prop-pricing.png')
//export const sliderRangeRover2 = require('./slider-2018-Range-Rover-Velgar.png')
//export const sliderPorsche = require('./slider-porsche-left.png')
//export const sliderPorsche2 = require('./slider-porsche-model.png')
//export const sliderRangeRover = require('./slider-rover-left.png')
//export const testimonialCar = require('./testimonial-car.png')
//export const testimonialUser = require('./testimonial-customer.png')



// export const arrowLeft = require('./arrow-left.svg')
// export const arrowRight = require('./arrow-right.svg')
// export const arrow = require('./arrow.svg')
// export const loader = require('./loader.svg')
// export const logoFooter = require('./logo-footer.svg')
// export const logoHeader = require('./logo.svg')
// export const loaderPre = require('./preLoader.svg')
// export const iconHP = require('./icon-hp.svg')
// export const iconMPG = require('./icon-mpg.svg')
// export const iconSpeed = require('./icon-speed.svg')