import React, { useState, useEffect } from 'react';
import { Grid, ButtonBase , Paper, Typography } from '@mui/material';
import moment from 'moment';
import Image from '../../assets/img/showcase22.png'  

const CountDownTimerBox = (props) => {
    const [countdownTimer, setCountdownTimer] = useState({days:'0', hours:'0', mins:'0', secs:'0'});
    const [countdownInfoMessage, setCountdownInfoMessage] = useState('');
    const [distance, setDistance] = useState(null)
    
    const eventName = 'Land of Blossoms'
    const event = 'NFT Public-Sale'

    //Set:   Mint Date, Time, AMPM
    const dateValue = '06-06-2022'
    const timeValue = '01:29'
    const ampmValue = 'pm'
    // Timestamp of Mint Date
    const unixEndDate = Number(moment(`${dateValue} ${timeValue} ${ampmValue}`, 'MM-DD-YYYY hh:mm A').format('X'));

    useEffect(() => {
        let timer = null;    //unixEndDate
        if (distance !== 0) {
            timer = setInterval(() => playTimer(), 1000);
        } 
        return () => {
            clearInterval(timer);
            timer = null;
        }
    }, [unixEndDate, eventName]);

    function playTimer() {
        const distance_temp = unixEndDate - moment().format('X');

        if (distance_temp > 0) { 
            setDistance(distance_temp);

            setCountdownTimer({
                days:  parseInt(distance_temp / (60 * 60 * 24), 10),
                hours: parseInt(distance_temp % (60 * 60 * 24) / (60 * 60), 10),
                mins: parseInt(distance_temp % (60 * 60) / (60), 10),
                secs: parseInt(distance_temp % 60, 10)
                });   
        }
        else {
            setDistance(0);
            setCountdownInfoMessage('Lamd of Blossoms NFT Public-Sale started.');
            setCountdownTimer({days:'0', hours:'0', mins:'0', secs:'0'});
            props.setIsCountDownEnded(true)
        }
  }

    const styles = {
        paperContainer: {
            backgroundImage: `url(${Image})`
        }
        };

    return (
        <>
        <Paper
            sx={{
                p: 10,
                mt:5,
                mr:10,
                maxWidth: 600,
                flexGrow: 1,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#acc0c7',
                }}
            elevation={12}
            style={{
                p: 50,
                border: "1.5px solid silver",
                borderRadius: "5px",
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography> Counting down to {eventName} {event} </Typography>
                    <Typography> on {moment.unix(unixEndDate).format('dddd, MMMM Do, YYYY | h:mm A')} (GMT+8) </Typography>
                </Grid>
                <Grid item xs={12}>
                     <ButtonBase sx={{ width: 256, height: 256, display:'col' }}>
                        <img alt="CountDownTimer" src={Image} />
                     </ButtonBase>
                </Grid>
                <Grid item xs={3}>
                    <Typography> Days </Typography>
                    <Typography> {countdownTimer.days} </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography> Hours </Typography>
                    <Typography> {countdownTimer.hours} </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography> Mins </Typography>
                    <Typography> {countdownTimer.mins} </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography> Secs </Typography>
                    <Typography> {countdownTimer.secs} </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography> {countdownInfoMessage} </Typography>       
                </Grid>
            </Grid>
        </Paper>
        </>
    )

}


export default CountDownTimerBox


/**
 <Paper
            sx={{
                p: 10,
                mt:1,
                maxWidth: 600,
                flexGrow: 1,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '95adaa',
                }}
            style={styles.paperContainer}
        >

*/