import React, { useEffect } from 'react';
import styled from 'styled-components';
import css from '../utils/cssVariables';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Typography, Grid, styled as styled2} from '@mui/material';
import showcase2 from '../assets/img/team1.png';
import showcase7 from '../assets/img/team2.png';
import showcase22 from '../assets/img/team6.png';
import showcase9 from '../assets/img/team7.png';
import showcase10 from '../assets/img/team3.png';

const ContentWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem ${css.paddingLarge};

  ${css.mobile} {
    margin-top: 50%;
    padding: 0 ${css.paddingSmall};
    flex-direction: column;
  }
`;

const Module = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 28vw;
  width: 28vw;
  background-color: #F5F5F7;

  ${css.mobile} {
    width: 92vw;
    height: 92vw;
    margin-bottom: 1rem;
  }

  img {
    height: 35%;
  }  
`;

const TextWrapper = styled.div`
  display: flex;   
  flex-direction: column;
  
  h3 {
    font-size: 2.1rem;
    line-height: 1;
    margin: 2.25rem auto 1rem;
    color='#5a6167'
  }

  p {
    white-space: pre-line;
    margin: 0px;
    font-size: 0.875rem; 
    color='#5a6167'
  }
`;

const Img = styled2('img')({
  margin: 'auto',
  display: 'block',
  width: 256, 
  height: 180,
});


const Team = () => {

  gsap.registerPlugin(ScrollTrigger);
  
  useEffect(() => {
    
    gsap.from( ".module", {
      scrollTrigger: {
        trigger: "#valueProps",
        start: "top center",
        },
      y: -100,
      duration: 0.5, 
      stagger: 0.25,
      opacity: 0,
    });
  });

  return (
    <>
    <Grid container spacing={2} mt='50px' id='team' >
        <Grid item xs={12} textAlign="center">
          <Typography variant="h3" component="div" color='#5a6167'> Team </Typography>
          <Typography variant="h5" component="div" color='#5a6167' m='10px'> Flowers & Birds Collection</Typography>
        </Grid>
    </Grid> 
        <ContentWrapper>
          <Module className="module">
            <Img src={showcase2} alt="Artists" />
            <TextWrapper>
              <h3>Artist</h3>
              <p>Watercolor & ink painter Wu YueQiu, specialized in painting birds, flowers and landscape, especially the nature beauty of the sub-tropical regions. </p>
            </TextWrapper>
          </Module>

          <Module className="module">
            <Img src={showcase22} alt="SP" />
            <TextWrapper>
              <h3>SI</h3>
              <p>Creator.                                                                                  <br/> Creates digital artworks based on the watercolor & ink paintings.</p>
            </TextWrapper>
          </Module>

          <Module className="module">
            <Img src={showcase7} alt="SU" />
            <TextWrapper>
            <h3>WAI</h3>
            <p>Collaborates with various types of artists, such as painters, sculpturers and digital artists, <br/>   to generate artwork for LOB collections . </p>
            </TextWrapper>
          </Module>
          
          <Module className="module">
            <Img src={showcase9} alt="FA" />
            <TextWrapper>
              <h3>TAO</h3>
              <p>In charge of business development, marketing and future comercialization of the LOB artworks,  <br/>    to create value for the LOB community.</p>
            </TextWrapper>
          </Module>

          <Module className="module">
            <Img src={showcase10} alt="WI" />
            <TextWrapper>
              <h3>YUAN</h3>
              <p>In charge of technology, developes the optimal technology platform for LOB,<br/> to create value for LOB via technologies. </p>
            </TextWrapper>
          </Module>
        </ContentWrapper>
    
    </>
  );
};

export default Team;